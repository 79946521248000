@charset "utf-8";
@use "variables" as *;
@use "mixins" as *;

html {
  opacity: 0.01;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;

  &.is-visible {
    opacity: 1;
  }

  @include desktop {
    min-width: $width-viewport;
    overflow: auto scroll;
    font-size: 625%;

    &.is-frozen-os {
      overflow: auto hidden;
    }
  }

  @include mobile {
    @include scroll-hidden;

    min-height: 100vh;
    font-size: calc(100vw / 3.75);

    &.is-frozen-os {
      overflow: hidden;
    }
  }
}

body {
  color: $color-default;
  font-family: $fontfamily-hiraginokakugothic;
  font-weight: $fontweight-light;
  line-height: $line-height;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  html.is-frozen-windows & {
    position: fixed;
    width: 100%;
  }

  @include desktop {
    font-size: $fontsize-regular-pc;
  }

  @include mobile {
    font-size: $fontsize-small-sp;
  }
}

main {
  @include desktop {
    min-height: calc(100vh - 4.09rem);
  }

  @include mobile {
    min-height: calc(100vh - 4.36rem);
  }
}

a {
  color: inherit;

  &:not([class]) {
    transition: text-decoration $animationduration ease;
    color: inherit;
    text-decoration: underline;
    text-decoration-color: inherit;
    text-underline-offset: 0.03rem;

    &:hover {
      text-decoration-color: transparent;
    }
  }
}

img,
svg {
  image-rendering: smooth;
  shape-rendering: geometricprecision;
}

.hidden {
  display: none;
}

.trans {
  transition: opacity $animationduration ease;
  opacity: 1;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }
}

.load {
  opacity: 0.001;

  html.is-visible & {
    opacity: 1;
  }
}

.image-common {
  display: block;
  width: 100%;
}

.object-common {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  font-feature-settings: "palt";

  @include desktop {
    width: $width-viewport;
    margin: 0 auto;
  }

  @include mobile {
    min-width: 320px;
  }
}

.wrapper {
  @include desktop {
    width: calc(100% - 0.6rem);
    max-width: $width-wrapper;
    margin-right: auto;
    margin-left: auto;
  }

  @include mobile {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
}

.sm {
  @include desktop {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

.md {
  @include mobile {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
