@charset "utf-8";
@use "base/base" as *;
@use "parts/header";
@use "parts/footer";
@use "parts/modules/wow";

.section-top-keyvisual {
  @include mobile {
    position: relative;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none;
    }
  }
}

.slider-top-keyvisual {
  pointer-events: none;

  .slider-item {
    pointer-events: none;
  }

  .swiper-slide {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .swiper-slide-prev,
  .swiper-slide-active,
  .swiper-slide-duplicate-active {
    img {
      animation-name: zoom;
      animation-duration: 15s;
      animation-fill-mode: forwards;
    }
  }

  @include desktop {
  }

  @include mobile {
  }
}

.banner-top-primary {
  position: relative;

  .banner-title,
  .banner-subtitle {
    position: absolute;
    z-index: 1;
    top: 0;
    margin: 0;
  }

  .banner-title {
    display: flex;
    flex-direction: column;
    border-radius: 0.15rem;
    background-color: rgba(40, 154, 255, 0.8);
    color: $color-white;
    font-weight: $fontweight-normal;
    letter-spacing: 0.025em;

    .title-large {
      font-weight: $fontweight-medium;
    }
  }

  .banner-subtitle {
    font-weight: $fontweight-normal;
    letter-spacing: 0.1em;
  }

  &.is-future {
    .title-large {
      line-height: 1;
    }

    .banner-subtitle {
      text-align: end;
    }
  }

  &.is-appraisals {
    .banner-title {
      background-color: rgba(65, 98, 200, 0.8);
    }
  }

  &.is-price {
    .banner-title {
      background-color: rgba(30, 90, 180, 0.8);
    }

    .banner-subtitle {
      text-align: end;
    }
  }

  &.is-consulting {
    .banner-title {
      &:first-of-type {
        background-color: rgba(30, 130, 200, 0.8);
      }

      &:last-of-type {
        background-color: rgba(34, 110, 144, 0.8);

        .title-large {
          display: flex;
          align-items: center;
        }

        .title-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  &.is-benefits {
    .banner-title {
      background-color: rgba(30, 90, 180, 0.8);
    }
  }

  @include desktop {
    .banner-subtitle {
      font-size: 0.32rem;
      line-height: calc(52 / 32);
    }

    .banner-title {
      padding: 28px 15px 26px 30px;

      .title-small {
        margin-bottom: 4px;
        font-size: 0.25rem;
        line-height: calc(34 / 25);
      }

      .title-large {
        font-size: 0.45rem;
        line-height: calc(53 / 45);
      }
    }

    &.is-future {
      .banner-title {
        top: calc(50% + 89px);
        left: 119px;
        min-width: 286px;
        height: 182px;
      }

      .title-small {
        margin-bottom: -9px;
      }

      .title-large > span {
        font-size: 0.68rem;
      }

      .banner-subtitle {
        top: calc(50% + 20px);
        right: 103px;
      }
    }

    &.is-appraisals {
      .banner-title {
        top: calc(50% - 270px);
        left: 121px;
        min-width: 298px;
        height: 231px;
      }

      .banner-subtitle {
        top: calc(50% + 41px);
        left: 122px;
      }
    }

    &.is-price {
      .banner-title {
        top: calc(50% + -270px);
        right: 119px;
        min-width: 482px;
        height: 195px;
      }

      .banner-subtitle {
        top: calc(50% + 66px);
        right: 109px;
      }
    }

    &.is-consulting {
      .banner-title {
        &:first-of-type {
          top: calc(50% + 15px);
          left: 120px;
          min-width: 415px;
          height: 255px;
        }

        &:last-of-type {
          top: calc(50% + -270px);
          right: 119px;
          min-width: 307px;
          height: 191px;

          .title-wrapper {
            font-size: 0.45rem;
          }

          .title-decoration {
            font-size: 0.1rem;
          }
        }
      }

      .banner-subtitle {
        top: calc(50% - 262px);
        left: 121px;
      }
    }

    &.is-benefits {
      .banner-title {
        top: calc(50% + 92px);
        right: 120px;
        min-width: 377px;
        height: 178px;
      }

      .banner-subtitle {
        top: calc(50% + 83px);
        left: 121px;
      }
    }
  }

  @include mobile {
    .banner-subtitle {
      font-size: $fontsize-small-sp;
    }

    .banner-title {
      padding: 0.14rem;

      .title-small {
        margin-bottom: 0.04rem;
        font-size: $fontsize-small-sp;
      }

      .title-large {
        font-size: $fontsize-small-sp;
      }
    }

    &.is-future {
      .banner-title {
        top: 0.8rem;
        left: 0.1rem;
      }

      .title-large > span {
        font-size: 0.22rem;
      }

      .banner-subtitle {
        top: 1rem;
        right: 0;
      }
    }

    &.is-appraisals {
      .banner-title {
        top: 0.1rem;
        left: 0;
      }

      .banner-subtitle {
        top: 1.4rem;
        left: 0.2rem;
      }
    }

    &.is-price {
      .banner-title {
        top: 0.75rem;
        right: 0;
        width: 1.87rem;
      }

      .banner-subtitle {
        top: 0.1rem;
        right: 0;
        width: 1.8rem;
      }
    }

    &.is-consulting {
      .banner-title {
        &:first-of-type {
          top: 0;
          left: 0;
          width: 1.52rem;
        }

        &:last-of-type {
          top: 0.85rem;
          right: 0;

          .title-decoration {
            font-size: 0.1rem;
          }
        }
      }

      .banner-subtitle {
        top: 0.1rem;
        right: 0;
        width: 1.47rem;
        text-align: right;
      }
    }

    &.is-benefits {
      .banner-title {
        top: 0.9rem;
        right: 0;
      }

      .banner-subtitle {
        top: 1.2rem;
        left: 0.1rem;
        width: 1.5rem;
      }
    }
  }
}

.section-top-introduction {
  .introduction-title {
    color: $color-green;
    font-weight: $fontweight-light;
    letter-spacing: 0.2em;
    text-align: center;
  }

  .content-title {
    font-family: $fontfamily-hiraginomincho;
    font-weight: $fontweight-light;
    letter-spacing: 0.05em;
  }

  .content-detail {
    font-family: $fontfamily-yugothic;
    font-weight: $fontweight-medium;
    letter-spacing: 0.05em;
  }

  @include desktop {
    padding: 145px 0 130px;

    .content-wrapper {
      display: flex;
    }

    .introduction-title {
      margin-bottom: 137px;
      font-size: 0.5rem;
    }

    .content-title {
      margin-bottom: 28px;
      font-size: 0.44rem;
    }

    .content-detail {
      font-size: $fontsize-demi-pc;
      line-height: calc(48 / 24);

      .detail-text {
        margin-right: -19px;
      }
    }

    .content-image {
      flex: 0 0 auto;
      width: 471px;
      margin-top: 3px;
      margin-right: -2px;
    }
  }

  @include mobile {
    padding: 0.7rem 0;

    .introduction-title {
      margin-bottom: 0.5rem;
      font-size: 0.3rem;
    }

    .content-title {
      margin-bottom: 0.2rem;
      font-size: $fontsize-large-sp;
      line-height: 1.5;
    }

    .content-detail {
      margin-bottom: 0.4rem;
      font-size: $fontsize-regular-pc;
      line-height: 1.75;
    }

    .content-image {
      width: 3.35rem;
    }
  }
}

.section-top-support {
  background-color: #dceef4;
  font-feature-settings: "palt";

  .support-title {
    color: $color-green;
    letter-spacing: 0.05em;
    text-align: center;

    @include reset-text;
  }

  .list-wrapper {
    @include reset-list;

    display: flex;
    flex-wrap: wrap;
    border: 1px solid $color-gray-tertiary;
  }

  .list-item {
    display: flex;
    align-items: center;
    border: 1px solid $color-gray-tertiary;
    font-weight: $fontweight-normal;
  }

  .item-content {
    flex: 1;

    .content-detail {
      display: block;
      font-weight: $fontweight-normal;
      margin: 0;
    }
  }

  .item-image {
    flex: 0 0 auto;
    width: 0.6rem;
    height: 0.6rem;
  }

  @include desktop {
    padding: 55px 0 56px;

    .wrapper {
      max-width: 1021px;
    }

    .support-title {
      margin-bottom: 24px;
      font-size: 0.38rem;
    }

    .list-wrapper {
      border-width: 1px 0 0 1px;
    }

    .list-item {
      width: calc(100% / 3);
      padding: 21px 24px 22px;
      border-width: 0 1px 1px 0;
    }

    .item-image {
      margin-right: 21px;
    }

    .item-content {
      padding-top: 2px;

      .content-detail {
        font-size: 0.27rem;
        line-height: calc(32 / 27);
      }
    }
  }

  @include mobile {
    padding: 0.6rem 0;

    .support-title {
      margin-bottom: 0.4rem;
      font-size: 0.26rem;
      line-height: 1.5;
    }

    .list-item {
      width: 100%;
      padding: 0.2rem;
      border-width: 0 0 1px;

      &:last-child {
        border-bottom: none;
      }
    }

    .item-image {
      margin-right: 0.2rem;
    }

    .item-content {
      .content-detail {
        font-size: 0.22rem;
      }
    }
  }
}

.section-top-product {
  .list-item {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      background: url("../img/top/product_decoration_01.svg") 50% 50% / 100% auto no-repeat;
    }

    &:nth-child(2) {
      .detail-title {
        display: flex;
      }
    }
  }

  .item-question {
    display: flex;
    background-color: $color-green;
    color: $color-white;
    font-weight: $fontweight-medium;
  }

  .detail-number {
    font-family: $fontfamily-sourcesans3;
    font-weight: 200;
    letter-spacing: -0.02em;
    line-height: 1;
  }

  .product-list {
    @include reset-list;
  }

  .question-text {
    letter-spacing: 0;

    p {
      margin-bottom: 0;
    }
  }

  .item-wrapper {
    text-decoration: none;
  }

  .item-content {
    position: relative;
    color: $color-white;
    letter-spacing: 0.025em;
  }

  .content-detail {
    display: flex;
    position: absolute;
  }

  .question-image {
    position: relative;
  }

  .detail-title {
    @include reset-text;

    margin-bottom: 0.1rem;

    .title-large {
      font-weight: $fontweight-medium;
    }

    .title-small {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title-decoration {
      font-size: 0.11rem;
    }
  }

  .detail-describe {
    display: flex;
    margin-bottom: 0;
    font-weight: $fontweight-medium;

    span {
      flex: 0 0 auto;
    }
  }

  @include desktop {
    padding: 144px 0 77px;

    .content-banner {
      width: 700px;
      height: 460px;
    }

    .item-question {
      position: absolute;
      z-index: 1;
      top: 200px;
      left: 50px;
      width: 350px;
      height: 230px;
      padding: 18px 0 18px 24px;
    }

    .question-image {
      width: 125px;
    }

    .item-content {
      max-width: 700px;
      margin-left: auto;
    }

    .content-detail {
      top: 22px;
      left: 29px;
    }

    .item-text {
      width: 190px;
    }

    .question-text {
      width: 186px;
      padding-top: 15px;
      font-size: $fontsize-demi-pc;
      line-height: calc(42 / 24);

      p {
        margin-right: -6px;
      }
    }

    .detail-number {
      flex: 0 0 auto;
      margin-right: 19px;
      padding-right: 10px;
      font-size: 1.49rem;
    }

    .detail-title {
      font-size: 0.48rem;
      line-height: calc(57 / 48);
    }

    .detail-describe {
      font-size: $fontsize-demi-pc;
      line-height: calc(34 / 24);
    }

    .detail-inner {
      padding-top: 15px;
    }

    .list-item {
      min-height: 460px;
      margin-bottom: 100px;
      overflow: hidden;

      &::before {
        top: 0;
        left: calc(50% - 320px);
        width: 640px;
        height: 460px;
      }

      &:nth-child(2) {
        .title-decoration {
          margin-top: -8px;
        }
      }

      &:nth-child(6) {
        .detail-inner {
          padding-top: 0;
        }

        .title-large {
          font-size: 0.72rem;
        }
      }

      &:nth-child(even) {
        &::before {
          top: 22px;
          left: calc(50% - 321px);
          transform: scale(-1);
        }

        .item-question {
          right: 50px;
          left: unset;
        }

        .item-content {
          margin-right: auto;
          margin-left: unset;
        }
      }
    }

    .list-item.is-auction {
      .question-text {
        width: 192px;
      }
    }

    .item-wrapper {
      .question-text,
      .question-image,
      .content-detail {
        transition: opacity $animationduration ease;
        opacity: 1;
      }

      &:hover {
        .question-text,
        .question-image,
        .content-detail {
          opacity: 0.8;
        }
      }
    }

    .title-top-primary {
      margin-bottom: 102px;
    }
  }

  @include mobile {
    padding: 0.6rem 0 0.2rem;

    .list-item {
      margin-bottom: 0.8rem;

      &::before {
        top: 0.7rem;
        left: 0.1rem;
        width: 3.2rem;
        height: 2.3rem;
      }
    }

    .item-wrapper {
      display: flex;
      flex-direction: column-reverse;
    }

    .question-image {
      bottom: -0.23rem;
      width: 0.63rem;
    }

    .item-question {
      z-index: 1;
      width: 2.5rem;
      min-height: 1.5rem;
      margin: -0.2rem auto 0;
      padding: 0.2rem 0 0.2rem 0.2rem;
    }

    .question-text {
      width: 1.6rem;
      font-size: $fontsize-medium-sp;
      line-height: 1.5;
    }

    .content-detail {
      top: 0.1rem;
      left: 0.12rem;
    }

    .detail-number {
      flex: 0 0 auto;
      width: 0.55rem;
      margin-right: 0.1rem;
      font-size: 0.6rem;
    }

    .detail-title {
      align-items: center;
      font-size: $fontsize-large-sp;

      .title-small {
        padding-left: 0.05rem;
      }
    }

    .detail-describe {
      font-size: 0.14rem;
      line-height: 1.5;

      span {
        margin-right: 0.07rem;
      }
    }

    .content-detail {
      padding-right: 0.1rem;
    }
  }
}

.section-top-news {
  @include desktop {
    padding-bottom: 67px;

    .title-top-primary {
      margin-bottom: 105px;
    }
  }

  @include mobile {
    padding: 0.4rem 0 0.6rem;
  }
}

.list-common-news {
  font-family: $fontfamily-yugothic;
  font-size: 0.15rem;
  font-weight: $fontweight-medium;
  letter-spacing: 0;

  .list-item {
    &:first-child {
      .item-inner {
        margin-top: 0;
      }
    }

    &:last-child {
      .item-inner {
        margin-bottom: 0;
        padding: 0.2rem 0.16rem;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 0.02rem;
          background: url("../img/top/news_decoration_01.svg") 0% 0% / auto 2px repeat;
        }
      }
    }
  }

  .item-introduction {
    display: flex;
    align-items: center;
  }

  .item-inner {
    display: flex;
    position: relative;
    margin: 0.06rem 0;
    padding: 0.25rem 0.16rem 0.15rem;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      height: 0.02rem;
      background: url("../img/top/news_decoration_01.svg") 0% 0% / auto 2px repeat;
    }

    &::before {
      top: 0;
    }
  }

  .item-label {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1rem;
    height: 0.25rem;
    background-color: $color-green;
    color: $color-white;
    font-size: 0.12rem;
  }

  .item-date {
    flex: 0 0 auto;
    width: 1.2rem;
    margin-right: 0.04rem;

    html.is-mac & {
      width: 1.26rem;
      margin-right: 0.1rem;
    }
  }

  .item-content {
    position: relative;
    flex: 1;
    padding-left: 0.26rem;
    letter-spacing: 0.04em;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 0.18rem;
      height: 0.18rem;
      background: url("../img/common/play_icn_01.svg") 50% 50% / 100% auto no-repeat;
    }
  }

  .label-text {
    position: relative;
  }

  @include reset-list;

  @include desktop {
    .item-inner {
      align-items: center;
    }

    .item-label {
      margin-right: 25px;

      html.is-mac & {
        margin-right: 20px;
      }
    }

    .item-content {
      &::before {
        top: calc(50% - 10px);
      }
    }

    .content-text {
      @include text-ellipse(1);
    }
  }

  @include mobile {
    .item-inner {
      flex-direction: column;
    }

    .item-introduction {
      margin-bottom: 0.2rem;
    }

    .item-content {
      &::before {
        top: calc(50% - 0.09rem);
      }
    }

    .content-text {
      @include text-ellipse(2);
    }

    .label-text {
      top: 0;
    }
  }
}

.title-top-primary {
  font-family: $fontfamily-hiraginomincho;
  font-weight: $fontweight-normal;
  letter-spacing: 0.05em;
  text-align: center;

  .title-wrapper {
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      height: 1px;
      transform: translateY(-50%);
      background-color: $color-black;
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  @include desktop {
    font-size: 0.34rem;

    .title-wrapper {
      padding: 0 110px;

      &::before {
        right: calc(100% - 81px);
      }

      &::after {
        left: calc(100% - 81px);
      }
    }
  }

  @include mobile {
    margin-bottom: 0.4rem;
    font-size: $fontsize-large-sp;

    .title-wrapper {
      padding: 0 50px;

      &::before {
        right: calc(100% - 0.35rem);
      }

      &::after {
        left: calc(100% - 0.35rem);
      }
    }
  }
}
