@charset "utf-8";
@use "../base/base" as *;

.header-common {
  position: fixed;
  z-index: 10000;
  background-color: $color-white;
  top: 0;
  left: 0;
  width: 100%;

  @include desktop {
    padding: 21px 39px 20px 45px;
    min-width: $width-viewport;

    & + main {
      padding-top: 175px;
    }
  }

  @include mobile {
    display: flex;
    align-items: center;
    min-height: 0.6rem;
    padding-left: 0.1rem;

    & + main {
      padding-top: .6rem;
    }
  }
}

.header-common.is-otoiawase {
  display: none;

  & + main {
    padding-top: 0;
  }
}

.header-wrapper {
  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.header-contact {
  @include mobile {
    margin-bottom: 0.3rem;
  }
}

.button-header-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-green;
  color: $color-white;
  font-weight: $fontweight-medium;
  letter-spacing: 0.05em;

  @include desktop {
    width: 260px;
    min-height: 50px;
    padding: 14.5px 20px 13.5px;
    font-size: 0.2rem;
    line-height: calc(22 / 20);
  }

  @include mobile {
    width: 2rem;
    min-height: 0.4rem;
    margin: 0 auto;
    padding: 0.1rem 0.1rem 0.11rem;
  }
}

.header-logo {
  display: block;
  margin-bottom: 0;

  @include desktop {
    width: 474px;
    margin-right: 50px;
  }

  @include mobile {
    width: 2.37rem;
    margin-right: 0.2rem;
  }
}

.navigation-header-sitemap {
  @include mobile {
    @include scroll-hidden;

    visibility: hidden;
    position: fixed;
    z-index: 10010;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
    opacity: 0;
    background-color: $color-white;
    pointer-events: none;

    .menu-sitemap {
      margin-bottom: 0.3rem;
      padding-top: 0.8rem;
    }

    .menu-wrapper {
      flex-direction: column;
      align-items: center;
    }

    .menu-child {
      border-bottom: 1px solid $color-default;
    }

    .child-wrapper {
      justify-content: center;
      width: 2rem;
      margin: 0.05rem 0;
      padding: 0.1rem;
      font-size: $fontsize-small-sp;
    }
  }
}

.button-header-burger {
  @include mobile {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;

    .button-line {
      position: absolute;
      top: calc(50% - 0.01rem);
      right: 0;
      left: 0;
      width: 0.24rem;
      height: 0.02rem;
      margin: 0 auto;
      transition: width $animationduration ease, transform $animationduration ease, opacity $animationduration ease;
      background-color: $color-default;

      &:nth-child(1) {
        transform: translateY(-0.09rem);
      }

      &:nth-child(3) {
        transform: translateY(0.09rem);
      }
    }
  }
}

.button-header-burger.is-active {
  @include mobile {
    .button-line {
      width: 0.26rem;

      &:nth-child(1) {
        transform: translateY(0) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: translateY(0) rotate(-45deg);
      }
    }
  }
}

.navigation-header-sitemap.is-ready {
  @include mobile {
    transition: opacity $animationduration ease, visibility $animationduration ease;
  }
}

.navigation-header-sitemap.is-active {
  @include mobile {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

.menu-sitemap {
  .menu-wrapper {
    @include reset-list;

    display: flex;
    justify-content: center;
  }

  .child-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: 200;
    letter-spacing: 0.03em;
    line-height: calc(23 / 18);
    text-align: center;
  }

  @include desktop {
    .child-wrapper {
      min-width: 62px;
      margin: 0 5px;
      padding: 2px 19px 1px 18px;

      html.is-tablet & {
        padding: 2px 18px 1px 17px;
      }
    }

    .menu-child {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 2px;
        right: 0;
        bottom: 1px;
        width: 1px;
        border-right: 1px solid $color-default;
      }

      &:first-child {
        .child-wrapper {
          margin-left: 0;
          padding-left: 0;
        }
      }

      &:last-child {
        &::after {
          content: none;
        }

        .child-wrapper {
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }
}
