@charset "utf-8";
@use "../base/base" as *;

.footer-common {
  position: relative;
  z-index: 9999;
}

.footer-wrapper {
  background-color: #e4f0f3;
  color: #282828;

  @include desktop {
    display: flex;
    justify-content: space-between;
    padding: 30px 38px 55px 44px;
  }

  @include mobile {
    padding: 0.3rem 0.2rem 0.1rem;
  }
}

.footer-information {
  font-family: $fontfamily-hiraginomincho;

  .footer-logo {
    display: block;
  }

  .information-phone {
    display: flex;
    align-items: center;
  }

  .phone-link {
    color: $color-green;
    font-family: $fontfamily-hiraginomincho;
    font-weight: $fontweight-light;
    line-height: 1;
  }

  .information-address {
    font-size: 0.14rem;
    line-height: calc(21 / 14);
  }

  .address-time {
    time {
      &:last-child {
        margin-left: -0.065rem;
      }
    }
  }

  @include desktop {
    padding-top: 4px;

    .footer-logo {
      width: 3.62rem;
      margin-bottom: 4px;
    }

    .information-address {
      margin-bottom: 10px;
    }

    .phone-icon {
      width: 54px;
      margin-right: 9px;
    }

    .phone-link {
      font-size: 0.45rem;
    }
  }

  @include mobile {
    margin-bottom: 0.3rem;

    .information-address {
      margin-bottom: 0.1rem;
    }

    .footer-logo {
      width: 2.6rem;
      margin-bottom: 0.2rem;
    }

    .phone-icon {
      width: 0.4rem;
    }

    .phone-link {
      font-size: 0.4rem;
    }
  }
}

.navigation-footer-sitemap {
  @include desktop {
    padding-bottom: 46px;
  }
}

.footer-list {
  .list-wrapper {
    @include reset-list;
  }

  .list-item {
    font-family: $fontfamily-hiraginomincho;
    font-size: 0.15rem;
    letter-spacing: 0.025em;
    line-height: calc(26 / 15);
  }

  .item-link {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      height: 1px;
      transform: translateY(-50%);
      border-bottom: 1px solid #282828;
    }
  }

  .item-link {
    padding-left: 0.14rem;
  }

  @include desktop {
    .list-item {
      text-align: right;
    }

    .item-link {
      padding-right: 14px;

      &::after {
        right: 0;
        width: 9px;
      }
    }
  }

  @include mobile {
    .list-item {
      margin-bottom: 0.06rem;
    }

    .item-link {
      padding-left: 0.14rem;

      &::after {
        left: 0;
        width: 0.07rem;
      }
    }
  }
}

.footer-copyright {
  display: flex;
  justify-content: center;
  background-color: #3c5160;
  color: $color-white;
  font-family: $fontfamily-hiraginomincho;
  font-size: 0.12rem;
  font-weight: 200;
  letter-spacing: 0.05em;

  @include desktop {
    padding: 0.1rem 0.4rem 0.26rem;
  }

  @include mobile {
    padding: 0.1rem 0.2rem .9rem;
    line-height: 1.5;
    text-align: center;

    html.is-otoawase & {
      padding: 0.1rem 0.2rem 0.8rem;
    }
  }
}

.footer-common.is-otoiawase {
  display: none;
}

.button-common-top {
  @include mobile {
    display: block;
    position: relative;
    z-index: 1001;
    width: 100%;
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;

    .wrapper {
      position: fixed;
      bottom: .05rem;
      right: .05rem;
      left: .05rem;
      display: block;
    }

    .contact-inner {
      right: 0;
      width: 100%;
      padding: 0.1rem .2rem;
      position: absolute;
      z-index: 1000;
      bottom: 0;
      background-color: rgba($color-gray-primary, .9);
      cursor: pointer;
      border-radius: .04rem;
    }

    .contact-button {
      background-color: $color-button;
      color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: .5rem;
      padding: .1rem;
      font-size: $fontsize-small-sp;
      line-height: 1.25;
      letter-spacing: .05em;
      font-weight: $fontweight-bold;
    }
  }
}

.button-common-top.is-static {
  @include mobile {
    .wrapper {
      position: absolute;
    }
  }
}

.button-common-top.is-visible {
  opacity: 1;
  pointer-events: auto;
}
