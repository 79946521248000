@charset "UTF-8";
html,
body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1.5em;
  font-weight: bold;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.4em;
}

h5 {
  font-size: 1.2em;
}

h6 {
  font-size: 1em;
}

ol,
ul {
  margin-bottom: 1.5em;
  padding-left: 1.5em;
}

table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  padding: 0.5em;
  border: 1px solid #000;
}

table th {
  font-weight: bold;
}

caption,
table th,
table td {
  font-weight: normal;
  text-align: left;
  vertical-align: middle;
}

q,
blockquote {
  quotes: none;
}

q::before,
q::after,
blockquote::before,
blockquote::after {
  content: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body * {
  max-height: 99999em;
  word-break: break-word;
}

p {
  margin-bottom: 1.5em;
}

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a img {
  border: none;
}

input,
label,
select,
button {
  line-height: inherit;
}

input,
label,
select,
button,
textarea {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  font: inherit;
  word-break: normal;
  vertical-align: middle;
  white-space: normal;
  -webkit-appearance: none;
}

button {
  color: inherit;
}

textarea {
  overflow: auto;
  line-height: 1.5;
  white-space: pre-line;
  resize: none;
}

button:focus {
  outline: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  display: none;
}

@media print {
  html,
  body {
    width: 1600px;
    margin: 0;
  }
  body {
    -webkit-print-color-adjust: exact;
  }
  @page {
    margin: 0;
    size: 11in 14.028in;
  }
  @page :left {
    margin: 0;
  }
  @page :right {
    margin: 0;
  }
  @page :first {
    margin-top: 0;
  }
}
html {
  opacity: 0.01;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}
html.is-visible {
  opacity: 1;
}
@media print, (min-width: 768px) {
  html {
    min-width: 1400px;
    overflow: auto scroll;
    font-size: 625%;
  }
  html.is-frozen-os {
    overflow: auto hidden;
  }
}
@media only screen and (max-width: 767.98px) {
  html {
    min-height: 100vh;
    font-size: 26.6666666667vw;
  }
  html::-webkit-scrollbar {
    width: 0;
    opacity: 0;
  }
  html.is-frozen-os {
    overflow: hidden;
  }
}

body {
  color: #3c3c3c;
  font-family: "ヒラギノ角ゴシック", "Hiragino Kaku Gothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", yugothic, "メイリオ", meiryo, sans-serif;
  font-weight: 300;
  line-height: 1.1666666667;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html.is-frozen-windows body {
  position: fixed;
  width: 100%;
}
@media print, (min-width: 768px) {
  body {
    font-size: 0.18rem;
  }
}
@media only screen and (max-width: 767.98px) {
  body {
    font-size: 0.16rem;
  }
}

@media print, (min-width: 768px) {
  main {
    min-height: calc(100vh - 4.09rem);
  }
}
@media only screen and (max-width: 767.98px) {
  main {
    min-height: calc(100vh - 4.36rem);
  }
}

a {
  color: inherit;
}
a:not([class]) {
  transition: text-decoration 0.5s ease;
  color: inherit;
  text-decoration: underline;
  text-decoration-color: inherit;
  text-underline-offset: 0.03rem;
}
a:not([class]):hover {
  text-decoration-color: transparent;
}

img,
svg {
  image-rendering: smooth;
  shape-rendering: geometricprecision;
}

.hidden {
  display: none;
}

.trans {
  transition: opacity 0.5s ease;
  opacity: 1;
  text-decoration: none;
}
.trans:hover {
  opacity: 0.8;
}

.load {
  opacity: 0.001;
}
html.is-visible .load {
  opacity: 1;
}

.image-common {
  display: block;
  width: 100%;
}

.object-common {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  font-feature-settings: "palt";
}
@media print, (min-width: 768px) {
  .container {
    width: 1400px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767.98px) {
  .container {
    min-width: 320px;
  }
}

@media print, (min-width: 768px) {
  .wrapper {
    width: calc(100% - 0.6rem);
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media only screen and (max-width: 767.98px) {
  .wrapper {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
}

@media print, (min-width: 768px) {
  .sm {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .md {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}

.header-common {
  position: fixed;
  z-index: 10000;
  background-color: #fff;
  top: 0;
  left: 0;
  width: 100%;
}
@media print, (min-width: 768px) {
  .header-common {
    padding: 21px 39px 20px 45px;
    min-width: 1400px;
  }
  .header-common + main {
    padding-top: 175px;
  }
}
@media only screen and (max-width: 767.98px) {
  .header-common {
    display: flex;
    align-items: center;
    min-height: 0.6rem;
    padding-left: 0.1rem;
  }
  .header-common + main {
    padding-top: 0.6rem;
  }
}

.header-common.is-otoiawase {
  display: none;
}
.header-common.is-otoiawase + main {
  padding-top: 0;
}

@media print, (min-width: 768px) {
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 767.98px) {
  .header-contact {
    margin-bottom: 0.3rem;
  }
}

.button-header-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1482a0;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media print, (min-width: 768px) {
  .button-header-contact {
    width: 260px;
    min-height: 50px;
    padding: 14.5px 20px 13.5px;
    font-size: 0.2rem;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 767.98px) {
  .button-header-contact {
    width: 2rem;
    min-height: 0.4rem;
    margin: 0 auto;
    padding: 0.1rem 0.1rem 0.11rem;
  }
}

.header-logo {
  display: block;
  margin-bottom: 0;
}
@media print, (min-width: 768px) {
  .header-logo {
    width: 474px;
    margin-right: 50px;
  }
}
@media only screen and (max-width: 767.98px) {
  .header-logo {
    width: 2.37rem;
    margin-right: 0.2rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .navigation-header-sitemap {
    visibility: hidden;
    position: fixed;
    z-index: 10010;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
    opacity: 0;
    background-color: #fff;
    pointer-events: none;
  }
  .navigation-header-sitemap::-webkit-scrollbar {
    width: 0;
    opacity: 0;
  }
  .navigation-header-sitemap .menu-sitemap {
    margin-bottom: 0.3rem;
    padding-top: 0.8rem;
  }
  .navigation-header-sitemap .menu-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .navigation-header-sitemap .menu-child {
    border-bottom: 1px solid #3c3c3c;
  }
  .navigation-header-sitemap .child-wrapper {
    justify-content: center;
    width: 2rem;
    margin: 0.05rem 0;
    padding: 0.1rem;
    font-size: 0.16rem;
  }
}

@media only screen and (max-width: 767.98px) {
  .button-header-burger {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
  }
  .button-header-burger .button-line {
    position: absolute;
    top: calc(50% - 0.01rem);
    right: 0;
    left: 0;
    width: 0.24rem;
    height: 0.02rem;
    margin: 0 auto;
    transition: width 0.5s ease, transform 0.5s ease, opacity 0.5s ease;
    background-color: #3c3c3c;
  }
  .button-header-burger .button-line:nth-child(1) {
    transform: translateY(-0.09rem);
  }
  .button-header-burger .button-line:nth-child(3) {
    transform: translateY(0.09rem);
  }
}

@media only screen and (max-width: 767.98px) {
  .button-header-burger.is-active .button-line {
    width: 0.26rem;
  }
  .button-header-burger.is-active .button-line:nth-child(1) {
    transform: translateY(0) rotate(45deg);
  }
  .button-header-burger.is-active .button-line:nth-child(2) {
    opacity: 0;
  }
  .button-header-burger.is-active .button-line:nth-child(3) {
    transform: translateY(0) rotate(-45deg);
  }
}

@media only screen and (max-width: 767.98px) {
  .navigation-header-sitemap.is-ready {
    transition: opacity 0.5s ease, visibility 0.5s ease;
  }
}

@media only screen and (max-width: 767.98px) {
  .navigation-header-sitemap.is-active {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

.menu-sitemap .menu-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}
.menu-sitemap .child-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 200;
  letter-spacing: 0.03em;
  line-height: 1.2777777778;
  text-align: center;
}
@media print, (min-width: 768px) {
  .menu-sitemap .child-wrapper {
    min-width: 62px;
    margin: 0 5px;
    padding: 2px 19px 1px 18px;
  }
  html.is-tablet .menu-sitemap .child-wrapper {
    padding: 2px 18px 1px 17px;
  }
  .menu-sitemap .menu-child {
    position: relative;
  }
  .menu-sitemap .menu-child::after {
    content: "";
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 1px;
    width: 1px;
    border-right: 1px solid #3c3c3c;
  }
  .menu-sitemap .menu-child:first-child .child-wrapper {
    margin-left: 0;
    padding-left: 0;
  }
  .menu-sitemap .menu-child:last-child::after {
    content: none;
  }
  .menu-sitemap .menu-child:last-child .child-wrapper {
    margin-right: 0;
    padding-right: 0;
  }
}

.footer-common {
  position: relative;
  z-index: 9999;
}

.footer-wrapper {
  background-color: #e4f0f3;
  color: #282828;
}
@media print, (min-width: 768px) {
  .footer-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 30px 38px 55px 44px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-wrapper {
    padding: 0.3rem 0.2rem 0.1rem;
  }
}

.footer-information {
  font-family: "ヒラギノ明朝", "Hiragino Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝", "Yu Mincho", yumincho, "MS Mincho", "MS 明朝", "SimSun", "宋体", "Times New Roman", georgia, serif;
}
.footer-information .footer-logo {
  display: block;
}
.footer-information .information-phone {
  display: flex;
  align-items: center;
}
.footer-information .phone-link {
  color: #1482a0;
  font-family: "ヒラギノ明朝", "Hiragino Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝", "Yu Mincho", yumincho, "MS Mincho", "MS 明朝", "SimSun", "宋体", "Times New Roman", georgia, serif;
  font-weight: 300;
  line-height: 1;
}
.footer-information .information-address {
  font-size: 0.14rem;
  line-height: 1.5;
}
.footer-information .address-time time:last-child {
  margin-left: -0.065rem;
}
@media print, (min-width: 768px) {
  .footer-information {
    padding-top: 4px;
  }
  .footer-information .footer-logo {
    width: 3.62rem;
    margin-bottom: 4px;
  }
  .footer-information .information-address {
    margin-bottom: 10px;
  }
  .footer-information .phone-icon {
    width: 54px;
    margin-right: 9px;
  }
  .footer-information .phone-link {
    font-size: 0.45rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-information {
    margin-bottom: 0.3rem;
  }
  .footer-information .information-address {
    margin-bottom: 0.1rem;
  }
  .footer-information .footer-logo {
    width: 2.6rem;
    margin-bottom: 0.2rem;
  }
  .footer-information .phone-icon {
    width: 0.4rem;
  }
  .footer-information .phone-link {
    font-size: 0.4rem;
  }
}

@media print, (min-width: 768px) {
  .navigation-footer-sitemap {
    padding-bottom: 46px;
  }
}

.footer-list .list-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer-list .list-item {
  font-family: "ヒラギノ明朝", "Hiragino Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝", "Yu Mincho", yumincho, "MS Mincho", "MS 明朝", "SimSun", "宋体", "Times New Roman", georgia, serif;
  font-size: 0.15rem;
  letter-spacing: 0.025em;
  line-height: 1.7333333333;
}
.footer-list .item-link {
  position: relative;
}
.footer-list .item-link::after {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  transform: translateY(-50%);
  border-bottom: 1px solid #282828;
}
.footer-list .item-link {
  padding-left: 0.14rem;
}
@media print, (min-width: 768px) {
  .footer-list .list-item {
    text-align: right;
  }
  .footer-list .item-link {
    padding-right: 14px;
  }
  .footer-list .item-link::after {
    right: 0;
    width: 9px;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-list .list-item {
    margin-bottom: 0.06rem;
  }
  .footer-list .item-link {
    padding-left: 0.14rem;
  }
  .footer-list .item-link::after {
    left: 0;
    width: 0.07rem;
  }
}

.footer-copyright {
  display: flex;
  justify-content: center;
  background-color: #3c5160;
  color: #fff;
  font-family: "ヒラギノ明朝", "Hiragino Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝", "Yu Mincho", yumincho, "MS Mincho", "MS 明朝", "SimSun", "宋体", "Times New Roman", georgia, serif;
  font-size: 0.12rem;
  font-weight: 200;
  letter-spacing: 0.05em;
}
@media print, (min-width: 768px) {
  .footer-copyright {
    padding: 0.1rem 0.4rem 0.26rem;
  }
}
@media only screen and (max-width: 767.98px) {
  .footer-copyright {
    padding: 0.1rem 0.2rem 0.9rem;
    line-height: 1.5;
    text-align: center;
  }
  html.is-otoawase .footer-copyright {
    padding: 0.1rem 0.2rem 0.8rem;
  }
}

.footer-common.is-otoiawase {
  display: none;
}

@media only screen and (max-width: 767.98px) {
  .button-common-top {
    display: block;
    position: relative;
    z-index: 1001;
    width: 100%;
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
  }
  .button-common-top .wrapper {
    position: fixed;
    bottom: 0.05rem;
    right: 0.05rem;
    left: 0.05rem;
    display: block;
  }
  .button-common-top .contact-inner {
    right: 0;
    width: 100%;
    padding: 0.1rem 0.2rem;
    position: absolute;
    z-index: 1000;
    bottom: 0;
    background-color: rgba(130, 130, 130, 0.9);
    cursor: pointer;
    border-radius: 0.04rem;
  }
  .button-common-top .contact-button {
    background-color: #b42328;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 0.5rem;
    padding: 0.1rem;
    font-size: 0.16rem;
    line-height: 1.25;
    letter-spacing: 0.05em;
    font-weight: 700;
  }
}

@media only screen and (max-width: 767.98px) {
  .button-common-top.is-static .wrapper {
    position: absolute;
  }
}

.button-common-top.is-visible {
  opacity: 1;
  pointer-events: auto;
}

.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in-up {
  -webkit-animation-name: fadeinup;
  animation-name: fadeInUp;
}

@media print {
  .wow {
    visibility: visible !important;
  }
}
@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
@media only screen and (max-width: 767.98px) {
  .section-top-keyvisual {
    position: relative;
    z-index: 1;
  }
  .section-top-keyvisual::after {
    content: "";
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
}

.slider-top-keyvisual {
  pointer-events: none;
}
.slider-top-keyvisual .slider-item {
  pointer-events: none;
}
.slider-top-keyvisual .swiper-slide {
  overflow: hidden;
}
.slider-top-keyvisual .swiper-slide img {
  width: 100%;
  height: auto;
}
.slider-top-keyvisual .swiper-slide-prev img,
.slider-top-keyvisual .swiper-slide-active img,
.slider-top-keyvisual .swiper-slide-duplicate-active img {
  animation-name: zoom;
  animation-duration: 15s;
  animation-fill-mode: forwards;
}
.banner-top-primary {
  position: relative;
}
.banner-top-primary .banner-title,
.banner-top-primary .banner-subtitle {
  position: absolute;
  z-index: 1;
  top: 0;
  margin: 0;
}
.banner-top-primary .banner-title {
  display: flex;
  flex-direction: column;
  border-radius: 0.15rem;
  background-color: rgba(40, 154, 255, 0.8);
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.025em;
}
.banner-top-primary .banner-title .title-large {
  font-weight: 500;
}
.banner-top-primary .banner-subtitle {
  font-weight: 400;
  letter-spacing: 0.1em;
}
.banner-top-primary.is-future .title-large {
  line-height: 1;
}
.banner-top-primary.is-future .banner-subtitle {
  text-align: end;
}
.banner-top-primary.is-appraisals .banner-title {
  background-color: rgba(65, 98, 200, 0.8);
}
.banner-top-primary.is-price .banner-title {
  background-color: rgba(30, 90, 180, 0.8);
}
.banner-top-primary.is-price .banner-subtitle {
  text-align: end;
}
.banner-top-primary.is-consulting .banner-title:first-of-type {
  background-color: rgba(30, 130, 200, 0.8);
}
.banner-top-primary.is-consulting .banner-title:last-of-type {
  background-color: rgba(34, 110, 144, 0.8);
}
.banner-top-primary.is-consulting .banner-title:last-of-type .title-large {
  display: flex;
  align-items: center;
}
.banner-top-primary.is-consulting .banner-title:last-of-type .title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner-top-primary.is-benefits .banner-title {
  background-color: rgba(30, 90, 180, 0.8);
}
@media print, (min-width: 768px) {
  .banner-top-primary .banner-subtitle {
    font-size: 0.32rem;
    line-height: 1.625;
  }
  .banner-top-primary .banner-title {
    padding: 28px 15px 26px 30px;
  }
  .banner-top-primary .banner-title .title-small {
    margin-bottom: 4px;
    font-size: 0.25rem;
    line-height: 1.36;
  }
  .banner-top-primary .banner-title .title-large {
    font-size: 0.45rem;
    line-height: 1.1777777778;
  }
  .banner-top-primary.is-future .banner-title {
    top: calc(50% + 89px);
    left: 119px;
    min-width: 286px;
    height: 182px;
  }
  .banner-top-primary.is-future .title-small {
    margin-bottom: -9px;
  }
  .banner-top-primary.is-future .title-large > span {
    font-size: 0.68rem;
  }
  .banner-top-primary.is-future .banner-subtitle {
    top: calc(50% + 20px);
    right: 103px;
  }
  .banner-top-primary.is-appraisals .banner-title {
    top: calc(50% - 270px);
    left: 121px;
    min-width: 298px;
    height: 231px;
  }
  .banner-top-primary.is-appraisals .banner-subtitle {
    top: calc(50% + 41px);
    left: 122px;
  }
  .banner-top-primary.is-price .banner-title {
    top: calc(50% - 270px);
    right: 119px;
    min-width: 482px;
    height: 195px;
  }
  .banner-top-primary.is-price .banner-subtitle {
    top: calc(50% + 66px);
    right: 109px;
  }
  .banner-top-primary.is-consulting .banner-title:first-of-type {
    top: calc(50% + 15px);
    left: 120px;
    min-width: 415px;
    height: 255px;
  }
  .banner-top-primary.is-consulting .banner-title:last-of-type {
    top: calc(50% - 270px);
    right: 119px;
    min-width: 307px;
    height: 191px;
  }
  .banner-top-primary.is-consulting .banner-title:last-of-type .title-wrapper {
    font-size: 0.45rem;
  }
  .banner-top-primary.is-consulting .banner-title:last-of-type .title-decoration {
    font-size: 0.1rem;
  }
  .banner-top-primary.is-consulting .banner-subtitle {
    top: calc(50% - 262px);
    left: 121px;
  }
  .banner-top-primary.is-benefits .banner-title {
    top: calc(50% + 92px);
    right: 120px;
    min-width: 377px;
    height: 178px;
  }
  .banner-top-primary.is-benefits .banner-subtitle {
    top: calc(50% + 83px);
    left: 121px;
  }
}
@media only screen and (max-width: 767.98px) {
  .banner-top-primary .banner-subtitle {
    font-size: 0.16rem;
  }
  .banner-top-primary .banner-title {
    padding: 0.14rem;
  }
  .banner-top-primary .banner-title .title-small {
    margin-bottom: 0.04rem;
    font-size: 0.16rem;
  }
  .banner-top-primary .banner-title .title-large {
    font-size: 0.16rem;
  }
  .banner-top-primary.is-future .banner-title {
    top: 0.8rem;
    left: 0.1rem;
  }
  .banner-top-primary.is-future .title-large > span {
    font-size: 0.22rem;
  }
  .banner-top-primary.is-future .banner-subtitle {
    top: 1rem;
    right: 0;
  }
  .banner-top-primary.is-appraisals .banner-title {
    top: 0.1rem;
    left: 0;
  }
  .banner-top-primary.is-appraisals .banner-subtitle {
    top: 1.4rem;
    left: 0.2rem;
  }
  .banner-top-primary.is-price .banner-title {
    top: 0.75rem;
    right: 0;
    width: 1.87rem;
  }
  .banner-top-primary.is-price .banner-subtitle {
    top: 0.1rem;
    right: 0;
    width: 1.8rem;
  }
  .banner-top-primary.is-consulting .banner-title:first-of-type {
    top: 0;
    left: 0;
    width: 1.52rem;
  }
  .banner-top-primary.is-consulting .banner-title:last-of-type {
    top: 0.85rem;
    right: 0;
  }
  .banner-top-primary.is-consulting .banner-title:last-of-type .title-decoration {
    font-size: 0.1rem;
  }
  .banner-top-primary.is-consulting .banner-subtitle {
    top: 0.1rem;
    right: 0;
    width: 1.47rem;
    text-align: right;
  }
  .banner-top-primary.is-benefits .banner-title {
    top: 0.9rem;
    right: 0;
  }
  .banner-top-primary.is-benefits .banner-subtitle {
    top: 1.2rem;
    left: 0.1rem;
    width: 1.5rem;
  }
}

.section-top-introduction .introduction-title {
  color: #1482a0;
  font-weight: 300;
  letter-spacing: 0.2em;
  text-align: center;
}
.section-top-introduction .content-title {
  font-family: "ヒラギノ明朝", "Hiragino Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝", "Yu Mincho", yumincho, "MS Mincho", "MS 明朝", "SimSun", "宋体", "Times New Roman", georgia, serif;
  font-weight: 300;
  letter-spacing: 0.05em;
}
.section-top-introduction .content-detail {
  font-family: "游ゴシック", yugothic, "Yu Gothic", "メイリオ", meiryo, sans-serif;
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media print, (min-width: 768px) {
  .section-top-introduction {
    padding: 145px 0 130px;
  }
  .section-top-introduction .content-wrapper {
    display: flex;
  }
  .section-top-introduction .introduction-title {
    margin-bottom: 137px;
    font-size: 0.5rem;
  }
  .section-top-introduction .content-title {
    margin-bottom: 28px;
    font-size: 0.44rem;
  }
  .section-top-introduction .content-detail {
    font-size: 0.24rem;
    line-height: 2;
  }
  .section-top-introduction .content-detail .detail-text {
    margin-right: -19px;
  }
  .section-top-introduction .content-image {
    flex: 0 0 auto;
    width: 471px;
    margin-top: 3px;
    margin-right: -2px;
  }
}
@media only screen and (max-width: 767.98px) {
  .section-top-introduction {
    padding: 0.7rem 0;
  }
  .section-top-introduction .introduction-title {
    margin-bottom: 0.5rem;
    font-size: 0.3rem;
  }
  .section-top-introduction .content-title {
    margin-bottom: 0.2rem;
    font-size: 0.24rem;
    line-height: 1.5;
  }
  .section-top-introduction .content-detail {
    margin-bottom: 0.4rem;
    font-size: 0.18rem;
    line-height: 1.75;
  }
  .section-top-introduction .content-image {
    width: 3.35rem;
  }
}

.section-top-support {
  background-color: #dceef4;
  font-feature-settings: "palt";
}
.section-top-support .support-title {
  color: #1482a0;
  letter-spacing: 0.05em;
  text-align: center;
  font-weight: 600;
}
html.is-android .section-top-support .support-title {
  font-weight: 700;
}
.section-top-support .list-wrapper {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #595757;
}
.section-top-support .list-item {
  display: flex;
  align-items: center;
  border: 1px solid #595757;
  font-weight: 400;
}
.section-top-support .item-content {
  flex: 1;
}
.section-top-support .item-content .content-detail {
  display: block;
  font-weight: 400;
  margin: 0;
}
.section-top-support .item-image {
  flex: 0 0 auto;
  width: 0.6rem;
  height: 0.6rem;
}
@media print, (min-width: 768px) {
  .section-top-support {
    padding: 55px 0 56px;
  }
  .section-top-support .wrapper {
    max-width: 1021px;
  }
  .section-top-support .support-title {
    margin-bottom: 24px;
    font-size: 0.38rem;
  }
  .section-top-support .list-wrapper {
    border-width: 1px 0 0 1px;
  }
  .section-top-support .list-item {
    width: 33.3333333333%;
    padding: 21px 24px 22px;
    border-width: 0 1px 1px 0;
  }
  .section-top-support .item-image {
    margin-right: 21px;
  }
  .section-top-support .item-content {
    padding-top: 2px;
  }
  .section-top-support .item-content .content-detail {
    font-size: 0.27rem;
    line-height: 1.1851851852;
  }
}
@media only screen and (max-width: 767.98px) {
  .section-top-support {
    padding: 0.6rem 0;
  }
  .section-top-support .support-title {
    margin-bottom: 0.4rem;
    font-size: 0.26rem;
    line-height: 1.5;
  }
  .section-top-support .list-item {
    width: 100%;
    padding: 0.2rem;
    border-width: 0 0 1px;
  }
  .section-top-support .list-item:last-child {
    border-bottom: none;
  }
  .section-top-support .item-image {
    margin-right: 0.2rem;
  }
  .section-top-support .item-content .content-detail {
    font-size: 0.22rem;
  }
}

.section-top-product .list-item {
  position: relative;
}
.section-top-product .list-item::before {
  content: "";
  position: absolute;
  background: url("../img/top/product_decoration_01.svg") 50% 50%/100% auto no-repeat;
}
.section-top-product .list-item:nth-child(2) .detail-title {
  display: flex;
}
.section-top-product .item-question {
  display: flex;
  background-color: #1482a0;
  color: #fff;
  font-weight: 500;
}
.section-top-product .detail-number {
  font-family: "Source Sans 3", sans-serif;
  font-weight: 200;
  letter-spacing: -0.02em;
  line-height: 1;
}
.section-top-product .product-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.section-top-product .question-text {
  letter-spacing: 0;
}
.section-top-product .question-text p {
  margin-bottom: 0;
}
.section-top-product .item-wrapper {
  text-decoration: none;
}
.section-top-product .item-content {
  position: relative;
  color: #fff;
  letter-spacing: 0.025em;
}
.section-top-product .content-detail {
  display: flex;
  position: absolute;
}
.section-top-product .question-image {
  position: relative;
}
.section-top-product .detail-title {
  font-weight: 600;
  margin-bottom: 0.1rem;
}
html.is-android .section-top-product .detail-title {
  font-weight: 700;
}
.section-top-product .detail-title .title-large {
  font-weight: 500;
}
.section-top-product .detail-title .title-small {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-top-product .detail-title .title-decoration {
  font-size: 0.11rem;
}
.section-top-product .detail-describe {
  display: flex;
  margin-bottom: 0;
  font-weight: 500;
}
.section-top-product .detail-describe span {
  flex: 0 0 auto;
}
@media print, (min-width: 768px) {
  .section-top-product {
    padding: 144px 0 77px;
  }
  .section-top-product .content-banner {
    width: 700px;
    height: 460px;
  }
  .section-top-product .item-question {
    position: absolute;
    z-index: 1;
    top: 200px;
    left: 50px;
    width: 350px;
    height: 230px;
    padding: 18px 0 18px 24px;
  }
  .section-top-product .question-image {
    width: 125px;
  }
  .section-top-product .item-content {
    max-width: 700px;
    margin-left: auto;
  }
  .section-top-product .content-detail {
    top: 22px;
    left: 29px;
  }
  .section-top-product .item-text {
    width: 190px;
  }
  .section-top-product .question-text {
    width: 186px;
    padding-top: 15px;
    font-size: 0.24rem;
    line-height: 1.75;
  }
  .section-top-product .question-text p {
    margin-right: -6px;
  }
  .section-top-product .detail-number {
    flex: 0 0 auto;
    margin-right: 19px;
    padding-right: 10px;
    font-size: 1.49rem;
  }
  .section-top-product .detail-title {
    font-size: 0.48rem;
    line-height: 1.1875;
  }
  .section-top-product .detail-describe {
    font-size: 0.24rem;
    line-height: 1.4166666667;
  }
  .section-top-product .detail-inner {
    padding-top: 15px;
  }
  .section-top-product .list-item {
    min-height: 460px;
    margin-bottom: 100px;
    overflow: hidden;
  }
  .section-top-product .list-item::before {
    top: 0;
    left: calc(50% - 320px);
    width: 640px;
    height: 460px;
  }
  .section-top-product .list-item:nth-child(2) .title-decoration {
    margin-top: -8px;
  }
  .section-top-product .list-item:nth-child(6) .detail-inner {
    padding-top: 0;
  }
  .section-top-product .list-item:nth-child(6) .title-large {
    font-size: 0.72rem;
  }
  .section-top-product .list-item:nth-child(even)::before {
    top: 22px;
    left: calc(50% - 321px);
    transform: scale(-1);
  }
  .section-top-product .list-item:nth-child(even) .item-question {
    right: 50px;
    left: unset;
  }
  .section-top-product .list-item:nth-child(even) .item-content {
    margin-right: auto;
    margin-left: unset;
  }
  .section-top-product .list-item.is-auction .question-text {
    width: 192px;
  }
  .section-top-product .item-wrapper .question-text,
  .section-top-product .item-wrapper .question-image,
  .section-top-product .item-wrapper .content-detail {
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  .section-top-product .item-wrapper:hover .question-text,
  .section-top-product .item-wrapper:hover .question-image,
  .section-top-product .item-wrapper:hover .content-detail {
    opacity: 0.8;
  }
  .section-top-product .title-top-primary {
    margin-bottom: 102px;
  }
}
@media only screen and (max-width: 767.98px) {
  .section-top-product {
    padding: 0.6rem 0 0.2rem;
  }
  .section-top-product .list-item {
    margin-bottom: 0.8rem;
  }
  .section-top-product .list-item::before {
    top: 0.7rem;
    left: 0.1rem;
    width: 3.2rem;
    height: 2.3rem;
  }
  .section-top-product .item-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .section-top-product .question-image {
    bottom: -0.23rem;
    width: 0.63rem;
  }
  .section-top-product .item-question {
    z-index: 1;
    width: 2.5rem;
    min-height: 1.5rem;
    margin: -0.2rem auto 0;
    padding: 0.2rem 0 0.2rem 0.2rem;
  }
  .section-top-product .question-text {
    width: 1.6rem;
    font-size: 0.18rem;
    line-height: 1.5;
  }
  .section-top-product .content-detail {
    top: 0.1rem;
    left: 0.12rem;
  }
  .section-top-product .detail-number {
    flex: 0 0 auto;
    width: 0.55rem;
    margin-right: 0.1rem;
    font-size: 0.6rem;
  }
  .section-top-product .detail-title {
    align-items: center;
    font-size: 0.24rem;
  }
  .section-top-product .detail-title .title-small {
    padding-left: 0.05rem;
  }
  .section-top-product .detail-describe {
    font-size: 0.14rem;
    line-height: 1.5;
  }
  .section-top-product .detail-describe span {
    margin-right: 0.07rem;
  }
  .section-top-product .content-detail {
    padding-right: 0.1rem;
  }
}

@media print, (min-width: 768px) {
  .section-top-news {
    padding-bottom: 67px;
  }
  .section-top-news .title-top-primary {
    margin-bottom: 105px;
  }
}
@media only screen and (max-width: 767.98px) {
  .section-top-news {
    padding: 0.4rem 0 0.6rem;
  }
}

.list-common-news {
  font-family: "游ゴシック", yugothic, "Yu Gothic", "メイリオ", meiryo, sans-serif;
  font-size: 0.15rem;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-common-news .list-item:first-child .item-inner {
  margin-top: 0;
}
.list-common-news .list-item:last-child .item-inner {
  margin-bottom: 0;
  padding: 0.2rem 0.16rem;
}
.list-common-news .list-item:last-child .item-inner::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.02rem;
  background: url("../img/top/news_decoration_01.svg") 0% 0%/auto 2px repeat;
}
.list-common-news .item-introduction {
  display: flex;
  align-items: center;
}
.list-common-news .item-inner {
  display: flex;
  position: relative;
  margin: 0.06rem 0;
  padding: 0.25rem 0.16rem 0.15rem;
}
.list-common-news .item-inner::before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  height: 0.02rem;
  background: url("../img/top/news_decoration_01.svg") 0% 0%/auto 2px repeat;
}
.list-common-news .item-inner::before {
  top: 0;
}
.list-common-news .item-label {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1rem;
  height: 0.25rem;
  background-color: #1482a0;
  color: #fff;
  font-size: 0.12rem;
}
.list-common-news .item-date {
  flex: 0 0 auto;
  width: 1.2rem;
  margin-right: 0.04rem;
}
html.is-mac .list-common-news .item-date {
  width: 1.26rem;
  margin-right: 0.1rem;
}
.list-common-news .item-content {
  position: relative;
  flex: 1;
  padding-left: 0.26rem;
  letter-spacing: 0.04em;
}
.list-common-news .item-content::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.18rem;
  height: 0.18rem;
  background: url("../img/common/play_icn_01.svg") 50% 50%/100% auto no-repeat;
}
.list-common-news .label-text {
  position: relative;
}
@media print, (min-width: 768px) {
  .list-common-news .item-inner {
    align-items: center;
  }
  .list-common-news .item-label {
    margin-right: 25px;
  }
  html.is-mac .list-common-news .item-label {
    margin-right: 20px;
  }
  .list-common-news .item-content::before {
    top: calc(50% - 10px);
  }
  .list-common-news .content-text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
@media only screen and (max-width: 767.98px) {
  .list-common-news .item-inner {
    flex-direction: column;
  }
  .list-common-news .item-introduction {
    margin-bottom: 0.2rem;
  }
  .list-common-news .item-content::before {
    top: calc(50% - 0.09rem);
  }
  .list-common-news .content-text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .list-common-news .label-text {
    top: 0;
  }
}

.title-top-primary {
  font-family: "ヒラギノ明朝", "Hiragino Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝", "Yu Mincho", yumincho, "MS Mincho", "MS 明朝", "SimSun", "宋体", "Times New Roman", georgia, serif;
  font-weight: 400;
  letter-spacing: 0.05em;
  text-align: center;
}
.title-top-primary .title-wrapper {
  position: relative;
}
.title-top-primary .title-wrapper::before, .title-top-primary .title-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  transform: translateY(-50%);
  background-color: #000;
}
.title-top-primary .title-wrapper::before {
  left: 0;
}
.title-top-primary .title-wrapper::after {
  right: 0;
}
@media print, (min-width: 768px) {
  .title-top-primary {
    font-size: 0.34rem;
  }
  .title-top-primary .title-wrapper {
    padding: 0 110px;
  }
  .title-top-primary .title-wrapper::before {
    right: calc(100% - 81px);
  }
  .title-top-primary .title-wrapper::after {
    left: calc(100% - 81px);
  }
}
@media only screen and (max-width: 767.98px) {
  .title-top-primary {
    margin-bottom: 0.4rem;
    font-size: 0.24rem;
  }
  .title-top-primary .title-wrapper {
    padding: 0 50px;
  }
  .title-top-primary .title-wrapper::before {
    right: calc(100% - 0.35rem);
  }
  .title-top-primary .title-wrapper::after {
    left: calc(100% - 0.35rem);
  }
}