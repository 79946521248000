@charset "utf-8";

$width-viewport: 1400px;
$width-wrapper: 1000px;
$fontfamily-hiraginokakugothic: "ヒラギノ角ゴシック", "Hiragino Kaku Gothic", "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3",
  "Hiragino Kaku Gothic ProN W3", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", yugothic, "メイリオ", meiryo, sans-serif;
$fontfamily-hiraginomincho: "ヒラギノ明朝", "Hiragino Mincho", "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3",
  "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "游明朝", "Yu Mincho", yumincho, "MS Mincho", "MS 明朝", "SimSun", "宋体", "Times New Roman", georgia, serif;
$fontfamily-yugothic: "游ゴシック", yugothic, "Yu Gothic", "メイリオ", meiryo, sans-serif;
$fontfamily-sourcesans3: "Source Sans 3", sans-serif;
$fontfamily-verdana: verdana, sans-serif;

$fontsize-tiny-pc: 0.16rem;
$fontsize-small-pc: 0.17rem;
$fontsize-regular-pc: 0.18rem;
$fontsize-medium-pc: 0.21rem;
$fontsize-demi-pc: 0.24rem;

$fontsize-small-sp: 0.16rem;
$fontsize-regular-sp: 0.17rem;
$fontsize-medium-sp: 0.18rem;
$fontsize-demi-sp: 0.21rem;
$fontsize-large-sp: 0.24rem;

$fontweight-light: 300;
$fontweight-normal: 400;
$fontweight-medium: 500;
$fontweight-demibold: 600;
$fontweight-bold: 700;

$line-height: calc(21 / 18);

$color-default: #3c3c3c;
$color-black: #000;
$color-white: #fff;
$color-gray-primary: #828282;
$color-gray-secondary: #56646b;
$color-gray-tertiary: #595757;
$color-gray-quaternary: #646464;
$color-gray-quinary: #aaa;
$color-green: #1482a0;
$color-sky: #289aff;
$color-red: #c80f28;
$color-button: #b42328;

$animationduration: 0.5s;
