@charset "utf-8";
@use "../../base/base" as *;

.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fade-in-up {
  -webkit-animation-name: fadeinup;
  animation-name: fadeInUp;
}

@media print {
  .wow {
    visibility: visible !important;
  }
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
